<template>
  <div class="table">
    <div class="table-title-section">
      <h3 class="make-title">{{title}}</h3>
      <Button v-if="tableData.length > 3" type="text" :iconRight='true' icon="fas icon-fa-angle-down" @click='showAllClicked' class="show-all" style="cursor:pointer">{{showAllText}}</Button>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" @row-click='rowClicked'>
      <el-table-column prop="Year" label="Year" />
      <el-table-column prop="Model" label="Model" />
      <el-table-column prop="SubModel" label="SubModel" />
      <el-table-column prop="Engine" label="Engine" />
    </el-table>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import Button from '@/components/buttons/Button'
import { ymmSelectionData } from '@/lib/ymm/YMMSelectionData'
export default {
  components: { Button },
  props: {
    data: Array,
    title: String
  },
  setup (props) {
    const tableData = ref(props.data.slice(0, 4))
    const showingAll = ref(false)
    const GlobalYMMQualifiers = inject('YMMQualifiersGlobal')
    const showAllClicked = () => {
      showingAll.value = !showingAll.value

      if (showingAll.value) tableData.value = props.data
      else tableData.value = props.data.slice(0, 4)
    }

    const showAllText = computed(() => {
      let returnText = ''
      if (showingAll.value) returnText = 'Collapse'
      else returnText = `Show All ${props.data.length}`

      return returnText
    })

    const rowClicked = async (_row, _column, _e) => {
      const dataFromURLSelection = await ymmSelectionData.getPartDataFromUrlQuery({ id: _row.urlParam })
      if (dataFromURLSelection) {
        GlobalYMMQualifiers.value.setupQualifiers({ ...dataFromURLSelection })
      }
    }

    return {
      tableData,
      showAllClicked,
      showAllText,
      rowClicked
    }
  }
}
</script>

<style lang='scss'>
.table{
    margin-bottom: 30px;
    .el-table__row{
      cursor: pointer !important;
    }
.table-title-section {
    display: flex;
    flex-direction: row;
    border-bottom: black solid thin;
    .show-all{
      width: 100%;
      text-align: right;
    }
    .make-title {
    display: flex;
    align-items: center;
    }
  }
}
</style>
