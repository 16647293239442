<template>
  <div class='pdp' v-if="activePdpData">
    <FixedHeader :title='activePdpData.pdpTitle'>
      <div class="links-container">
        <Button
          class="secondary-nav-link"
          type="text"
          icon=""
          v-if="compatibleVehicleData.length > 0"
          @click="scrollTo('compatible-vehicle-container')"
          >COMPATIBLE VEHICLES</Button
        >
        <Button
        class="secondary-nav-link"
          type="text"
          icon=""
          @click="scrollTo('spec-list')"
          >SEE ALL SPECIFICATIONS</Button
        >
        <Button
        class="secondary-nav-link"
          type="text"
          icon=""
          @click="scrollTo('help-container')"
          >WARRANTY</Button
        >
        <Button
        class="secondary-nav-link"
          type="text"
          icon=""
          @click="scrollTo('diy')"
          >INSTALLATION INFORMATION</Button
        >
        </div>
    </FixedHeader>
  <div class="pdp-container">
    <Gallery v-if="activePdpData.galleryAssets" :galAssets="activePdpData.galleryAssets" />
    <PartDescription v-if="activePdpData.featuresIcons" :sku="activePdpData.sku" :data="activePdpData.featuresIcons" :description="activePdpData.descriptionText" :partNumber="activePdpData.partNumber" :clientURL="activePdpData.clientURL || 'NA'" />
    <CompatibleVehicles v-if="compatibleVehicleData.length > 0" :compatibleVehicleData="compatibleVehicleData" />
    <BuildSpecs v-if="activePdpData.buildSpecs" :specData="activePdpData.buildSpecs" :installationGuide="activePdpData.installationGuide" />
    <Help :warrantyImage="activePdpData.warrantyImage" />
  </div>
  </div>
  <div v-else-if="loading" class='pdp'>
    <FixedHeader />
  </div>
  <div v-else class='pdp'>
    <FixedHeader title='Sorry, this product does not exist' />
  </div>
</template>

<script>
import Gallery from '@/components/gallery/Gallery'
import PartDescription from '@/components/pdp/PartDescription'
import CompatibleVehicles from '@/components/pdp/compatibleVehicles/CompatibleVehicles'
import BuildSpecs from '@/components/pdp/BuildSpecs'
import Help from '@/components/pdp/Help'
import { useGetters, useMutations } from '@/lib/helpers'
import FixedHeader from '@/components/global/FixedHeader'
import Button from '@/components/buttons/Button'
import gsap from 'gsap'
import { getAPIObjFor } from '@/lib/ymm/YMMApi'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import store from '../store'

export default {
  components: {
    Gallery,
    PartDescription,
    CompatibleVehicles,
    BuildSpecs,
    Help,
    FixedHeader,
    Button
  },
  setup () {
    const activePdpData = ref(false)
    const compatibleVehicleData = ref([])
    const route = useRoute()
    const { setSelectedPart } = useMutations(['setSelectedPart'])
    const loading = ref(true)
    onMounted(async () => {
      if (route.params.modal === 'true') {
        await SetSwitchGlobalYmmModal()
      }
      loading.value = true
      const { pdpData } = useGetters(['pdpData'])
      // check if store data is same part number as url
      const storeHasCorrectPDPData = (pdpData.value.partNumber === route.params.id || pdpData.value.sku === route.params.id)
      // if store had our data, ets save it
      if (storeHasCorrectPDPData) {
        activePdpData.value = pdpData.value
        console.log(JSON.stringify(activePdpData))
      } else {
        // else store did not have the proper data, lets do a search by sku to see if it has the data
        const { apiRequestUrl, apiRequest } = getAPIObjFor('Search')(route.params.id)
        const pdpDataRequest = await apiRequest(apiRequestUrl) || false
        setSelectedPart(pdpDataRequest)
        activePdpData.value = pdpData.value
      }
      loading.value = false
      if (!activePdpData.value) return
      // do this initialization stuff if we have an active product
      const { apiRequestUrl, apiRequest } = await getAPIObjFor('CompatibleVehicles')(activePdpData.value.partNumber)
      scrollTo('el-container')
      compatibleVehicleData.value = await (apiRequest(apiRequestUrl))
    })

    const scrollTo = (_scrollClass) => {
      const scrollToTop = document.querySelector(`[class~="${_scrollClass}"]`).offsetTop
      if (scrollToTop >= 0) {
        gsap.to([document.documentElement, document.body], {
          scrollTop: scrollToTop - 175
        })
      }
    }

    async function SetSwitchGlobalYmmModal () {
      const { apiRequestUrl, apiRequest } = await getAPIObjFor('MakeModel')(route.params.make, route.params.modelsubmodels)
      const makeModelData = await (apiRequest(apiRequestUrl))
      const make = makeModelData.make
      const model = makeModelData.model
      const payload = {
        year: route.params.year,
        makeID: make.id,
        makeName: make.name,
        modelID: model.id,
        modelName: model.name
      }
      // store.commit('switchGlobalYmmModal', { isOpen: true, payload: payload })
      store.commit('switchGlobalYmmModal', { isOpen: true, displayNoDataAvailable: true, payload: payload })
    }

    return { activePdpData, scrollTo, compatibleVehicleData, loading, SetSwitchGlobalYmmModal }
  }
}
</script>

<style lang="scss">
  a{
    color:inherit;
    text-decoration: none;
    font-size: inherit
  }
  .links-container .el-button{
    margin-right: 40px;
    min-height: 0px;
  }
  .fixed-header{

  }
.pdp-container {
  display: flex;
  max-width: 1024px;
  margin: 0 auto;
  flex-direction: column;
  padding: 0 28px;
  padding-top: calc(#{$fixed-header-height} + 30px);
}
</style>
